import React, { FunctionComponent } from "react";

import { Layout } from "antd";
import styled from "@emotion/styled";

const StyledLayout = styled(Layout)`
  background: none;
`;

const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper: FunctionComponent = ({ children }) => {
  return (
    <StyledLayout>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  );
};
