import React, { FunctionComponent } from "react";

import SEO from "../components/Seo";
import { DefaultLayout } from "../components/DefaultLayout";

const NotFoundPage: FunctionComponent = () => (
  <>
    <SEO title="404: Not found" />
    <DefaultLayout>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </DefaultLayout>
  </>
);

export default NotFoundPage;
