import React, { FunctionComponent } from "react";

import { BaseLayout } from "./BaseLayout";
import { ContentWrapper } from "./ContentWrapper";

export const DefaultLayout: FunctionComponent = ({ children }) => {
  return (
    <BaseLayout>
      <ContentWrapper>{children}</ContentWrapper>
    </BaseLayout>
  );
};
